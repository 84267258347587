$(document).on('ready turbolinks:load', function() {
  $('.calendar').fullCalendar('destroy').empty();
  setTimeout(function() {
    $('.calendar').fullCalendar({
      header: {
        left: 'prev,next',
        center: 'title',
        right: 'today',
      },
      allDayDefault: true,
      startParam: 'date_start',
      endParam: 'date_end',
      events: {
        url: '/api/v2/pages/?type=events.EventPage&fields=date_start,time_start,date_end,time_end',
        success: function(response) {
          return response.items;
        },
      },
      eventRender: function(event, element) {
        $(element).tooltip({
          title: event.title,
        });
      },
      eventClick: function(calEvent) {
        Turbolinks.visit(calEvent.url);
      },
      eventDataTransform: function(event) {
        return {
          title: event.title,
          start: event.date_start,
          end: event.date_end,
          url: event.meta.html_url,
        };
      },
    });
  }, 1000);
});
