$(document).on('ready turbolinks:load', function() {
  // Setup pickadate for easy date selection
  $('#id_date_start, #id_date_end').pickadate({
    format: 'yyyy-mm-dd',
    formatSubmit: 'yyyy-mm-dd',
    hiddenName: true,
  });
  $('#id_time_start, #id_time_end').pickatime({
    format: 'HH:i',
    formatSubmit: 'HH:i',
    hiddenName: true,
  });


  // On selecting a date via pickadate auto submit form
  $('form.filters').find('input, select').each(function() {
    $(this).change(function() {
      setTimeout(function() {
        const form = $('form.filters');
        Turbolinks.visit('?' + form.serialize());
      }, 1000);
    });
  });
});
