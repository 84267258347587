document.addEventListener("turbolinks:load", function() {
  $('#homeSlider').slick({
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    appendDots: $('#homeDots'),
    autoplay: true,
  });
});


document.addEventListener("turbolinks:before-cache", function() {
  $('#homeSlider').slick('unslick');
});
