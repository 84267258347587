document.addEventListener("turbolinks:load", function() {
  $('.images__large').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: $('.images__small'),
  });

  $('.images__small').slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    asNavFor: $('.images__large'),
    dots: true,
    appendDots: $('.images-sm-dots'),
    centerMode: true,
    arrows: false,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 991.98,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767.98,
        settings: {
          slidesToShow: 3,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          dots: false,
        },
      },
    ],
  });
});


document.addEventListener("turbolinks:before-cache", function() {
  $('.images__large, .images__small').slick('unslick');
});
