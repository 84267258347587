document.addEventListener("turbolinks:load", function() {
  // change href # to none
  $("a[href='#']").click(function(event) {  // eslint-disable-line
    event.preventDefault();
  });

  // for any tooltips
  $('[data-toggle="tooltip"]').tooltip();

  // hide slick slider dots if only one slide
  $('.slick-dots li:only-child').closest('.slider__controls').hide();

  // add class to select parents for custom styles
  $('select').parent('div').addClass('select-parent');

  $('#wineCarousel').slick({
    dots: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    prevArrow: $('#winePrev'),
    nextArrow: $('#wineNext'),
    appendDots: $('#wineDots'),
    responsive: [
      {
        breakpoint: 1199.98,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: true,
          dots: true,
        },
      },
      {
        breakpoint: 991.98,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: true,
          dots: false,
        },
      },
      {
        breakpoint: 767.98,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
});


document.addEventListener("turbolinks:before-cache", function() {
  $('#wineCarousel').slick('unslick');
});
