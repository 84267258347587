$(document).on('ready turbolinks:load', function() {
  // scroll
  $(window).on('scroll', function () {
    if ($(this).scrollTop() > 50) {
      if (!$('.navbar').hasClass('navbar--scrolled')) {
        $('.navbar').addClass('navbar--scrolled');
      }
    } else {
      if ($('.navbar').hasClass('navbar--scrolled')) {
        $('.navbar').removeClass('navbar--scrolled');
      }
    }
  });

  // menu
  $('.hamburger').on('click', function() {
    $(this).toggleClass('hamburger--clicked');
    $('#menu').slideToggle(350);
    if ($(this).hasClass('hamburger--clicked')) {
      $('body').css('overflow', 'hidden');
      $('.navbar').addClass('navbar--opened');
    } else {
      $('body').css('overflow', 'auto');
      $('.navbar').removeClass('navbar--opened');
    }
  });
});

$(document).on('turbolinks:before-cache', () => {
  $('.hamburger').removeClass('hamburger--clicked');
  $('.navbar').removeClass('navbar--scrolled navbar--opened');
  $('#search').removeClass('search--scrolled search--opened');

  $('#search').hide();
  $('#menu').hide();

  $('body').css('overflow', 'auto');
});
