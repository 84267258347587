document.addEventListener("turbolinks:load", function() {
  $('#venueSlider').slick({
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    appendDots: $('#venueDots'),
    autoplay: true,
  });
  $('.datetimeinput').pickadate({
    format: 'yyyy-mm-dd',
    formatSubmit: 'yyyy-mm-dd',
    hiddenName: true,
  });
});


document.addEventListener("turbolinks:before-cache", function() {
  $('#venueSlider').slick('unslick');
});
