$(document).on('ready turbolinks:load', function() {
  $('#searchOpen, #searchOpen2').on('click', function() {
    $('#search').fadeIn(350);
    $('body').css('overflow', 'hidden');
    $('.search__input').focus();
    $('.search__input').select();
    $('.menu').hide();
    $('.hamburger').removeClass('hamburger--clicked');
    $('.navbar').removeClass('navbar--opened');
  });
  $('#searchClose').on('click', function() {
    $('#search').fadeOut(350);
    $('body').css('overflow', 'auto');
  });

  // scroll
  $(window).on('scroll', function () {
    if ($(this).scrollTop() > 50) {
      if (!$('.search').hasClass('search--scrolled')) {
        $('.search').addClass('search--scrolled');
      }
    } else {
      if ($('.search').hasClass('search--scrolled')) {
        $('.search').removeClass('search--scrolled');
      }
    }
  });
});
